import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { useAuthStore } from "@/stores/auth";

const provider = new OAuthProvider("microsoft.com");
// provider.setCustomParameters({
//   login_hint: "<<username>>@uncoded.com",
//   tenant: process.env.VUE_APP_TENANT_ID,
// });

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth();

const login = async () => {
  const authStore = useAuthStore();
  const result = await signInWithPopup(auth, provider);
  const credential = OAuthProvider.credentialFromResult(result);
  const user = await getADInfo(credential?.accessToken);
  authStore.setUser(user);
};

const logout = async () => {
  const authStore = useAuthStore();
  await signOut(auth);
  authStore.setUser(null);
};

const getADInfo = async (token) => {
  const response = await fetch(`https://graph.microsoft.com/v1.0/me`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  console.log(data);

  return data;
};

export { app, auth, login, logout };
